import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";

import Node from "./node";
import { IFrameInfo, TICKER_SYMBOL, ITicker } from "./injections/ticker";
import provider from "./provider";

export default abstract class Leaf {
  private _parent: Node | null;
  private _parentAttachSubscription: Subscription | null;
  private _initialized: boolean;

  protected readonly attach$: Observable<IAttachInfo>;
  protected readonly detach$: Observable<IDetachInfo>;
  protected readonly attachBehaviorSubject$: BehaviorSubject<boolean | null>;

  protected frameTick$: Observable<IFrameInfo>;

  protected get parent() {
    return this._parent;
  }

  constructor() {
    this._parent = null;
    this._parentAttachSubscription = null;
    this._initialized = false;

    // Attach to / detach from scene
    this.attachBehaviorSubject$ = new BehaviorSubject<boolean | null>(null);
    this.attach$ = this.attachBehaviorSubject$.pipe(
      filter(x => !!x),
      map(() => ({ firstTime: this._initialized })),
      tap(() => {
        if (!this._initialized) {
          this._initialized = true;
        }
      })
    );
    this.detach$ = this.attachBehaviorSubject$.pipe(
      filter(x => x !== null && !x),
      map(() => ({}))
    );

    // Ticker
    this.frameTick$ = provider
      .resolve<ITicker>(TICKER_SYMBOL)
      .frameTicker$.pipe(
        withLatestFrom(this.attachBehaviorSubject$),
        filter(([, attached]) => !!attached),
        map(([frameInfo]) => frameInfo)
      );
  }

  /**
   * Sets the parent of this component.
   * @param parent The new parent of this component
   */
  public setParent(parent: Node) {
    if (this.parent) {
      this.orphan();
    }
    this._parentAttachSubscription = parent.attachBehaviorSubject$.subscribe(
      x => this.attachBehaviorSubject$.next(x)
    );
    this._parent = parent;
  }

  /**
   * Clears the parent of this node.
   */
  public orphan() {
    if (this._parentAttachSubscription) {
      this._parentAttachSubscription.unsubscribe();
    }
    this.attachBehaviorSubject$.next(false);
  }
}

export interface IAttachInfo {
  firstTime: boolean;
}

export interface IDetachInfo {}

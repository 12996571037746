import { Observable, of, concat, from } from "rxjs";
import {
  withLatestFrom,
  take,
  map,
  takeWhile,
  concat as concatOp,
} from "rxjs/operators";

import { EasingFunction } from "./easing";
import { IFrameInfo } from "../injections/ticker";
import { mixNumber, mixVec2 } from "../common/matrix-stuff";
import { vec2 } from "gl-matrix";

export function playAnimation<T>(
  fromValue: T,
  keyFrames: IKeyFrame<T>[],
  frameTick$: Observable<IFrameInfo>,
  mix: (a: T, b: T, alpha: number) => T
): Observable<T> {
  const kfs = keyFrames.reduce((acc, cur, i, a) => {
    acc.push([a[i - 1]?.toValue ?? fromValue, cur]);
    return acc;
  }, new Array<[T, IKeyFrame<T>]>());

  const keyFrames$ = kfs.map(([fromValue, keyFrame]) =>
    animate(
      fromValue,
      keyFrame.toValue,
      keyFrame.timeSeconds,
      keyFrame.easing,
      frameTick$,
      mix
    )
  );

  return concat(...keyFrames$);
}

export function playNumberAnimation(
  fromValue: number,
  keyFrames: IKeyFrame<number>[],
  frameTick$: Observable<IFrameInfo>
): Observable<number> {
  return playAnimation(fromValue, keyFrames, frameTick$, mixNumber);
}

export function playVec2Animation(
  fromValue: vec2,
  keyFrames: IKeyFrame<vec2>[],
  frameTick$: Observable<IFrameInfo>
): Observable<vec2> {
  let out = vec2.create();
  const mix = (a: vec2, b: vec2, alpha: number) => mixVec2(out, a, b, alpha);
  return playAnimation(fromValue, keyFrames, frameTick$, mix);
}

export function animate<T>(
  fromValue: T,
  toValue: T,
  timeSeconds: number,
  easing: EasingFunction,
  frameTick$: Observable<IFrameInfo>,
  mix: (a: T, b: T, alpha: number) => T
): Observable<T> {
  return frameTick$.pipe(
    withLatestFrom(frameTick$.pipe(take(1))),
    map(([cur, first]) => cur.timeSeconds - first.timeSeconds),
    takeWhile((t) => t < timeSeconds),
    concatOp(of(timeSeconds)),
    map((t) => mix(fromValue, toValue, easing(t / timeSeconds)))
  );
}

export function animateNumber(
  fromValue: number,
  toValue: number,
  timeSeconds: number,
  easing: EasingFunction,
  frameTick$: Observable<IFrameInfo>
): Observable<number> {
  return animate(
    fromValue,
    toValue,
    timeSeconds,
    easing,
    frameTick$,
    mixNumber
  );
}

export function animateVec2(
  fromValue: vec2,
  toValue: vec2,
  timeSeconds: number,
  easing: EasingFunction,
  frameTick$: Observable<IFrameInfo>
): Observable<vec2> {
  let out = vec2.create();
  const mix = (a: vec2, b: vec2, alpha: number) => mixVec2(out, a, b, alpha);
  return animate(fromValue, toValue, timeSeconds, easing, frameTick$, mix);
}

export interface IKeyFrame<T> {
  toValue: T;
  timeSeconds: number;
  easing: EasingFunction;
}

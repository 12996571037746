import { Observable, fromEvent, BehaviorSubject, asyncScheduler } from "rxjs";
import { map, debounceTime, throttleTime } from "rxjs/operators";

export default class Screen {
  private readonly _currentSize$: BehaviorSubject<ScreenInfo>;
  private readonly _parent: HTMLElement | null;

  /**
   * Streams the current screen size; Immediately emits the current screen size to new subscribers!
   */
  public get resize$(): Observable<ScreenInfo> {
    return this._currentSize$;
  }

  public fireResize() {
    this._currentSize$.next(this.getScreenInfo());
  }

  constructor(parentHtmlElement?: HTMLElement) {
    this._parent = parentHtmlElement || null;
    this._currentSize$ = new BehaviorSubject<ScreenInfo>(this.getScreenInfo());
    fromEvent(window, "resize")
      .pipe(
        debounceTime(50, asyncScheduler),
        map(() => this.getScreenInfo())
      )
      .subscribe((x) => this._currentSize$.next(x));
  }

  private getScreenInfo(): ScreenInfo {
    const width = this._parent?.clientWidth ?? window.innerWidth;
    const height = this._parent?.clientHeight ?? window.innerHeight;
    return {
      width,
      height,
      ratio: width / height,
    };
  }
}

export interface ScreenInfo {
  width: number;
  height: number;
  ratio: number;
}

import Scene from "./scene";
import { Ticker, TICKER_SYMBOL } from "./injections/ticker";
import Renderer from "./rendering/renderer";
import provider from "./provider";
import Screen from "./injections/screen";
import Input from "./injections/input";

export default abstract class Game {
  private _renderer: Renderer;
  private _isRunning = false;
  private _currentScene: Scene | null = null;

  public get rendererIsWorking() {
    return this._renderer.isWorking;
  }

  constructor(parentHtmlElement?: HTMLElement) {
    const parent = parentHtmlElement || document.body;

    // Screen
    const screen = new Screen(parentHtmlElement);

    // create the renderer
    this._renderer = new Renderer(parent.scrollWidth, parent.scrollHeight);
    if (this.rendererIsWorking) {
      parent.appendChild(this._renderer.view);
      screen.resize$.subscribe((screenInfo) => {
        this._renderer.resize(screenInfo.width, screenInfo.height);
      });
    }

    // render on every frame tick!
    const ticker = new Ticker();
    if (this.rendererIsWorking) {
      ticker.frameTicker$.subscribe(() => {
        this._currentScene?.computeChildrenWorldTransforms();
        this._renderer.render();
      });
    }

    // Register instances
    provider.registerInstance(Screen, screen);
    provider.registerInstance(Renderer, this._renderer);
    provider.registerInstance(TICKER_SYMBOL, ticker);
    provider.registerInstance(Input, new Input());
  }

  /**
   * Let's play!
   */
  public run() {
    this._isRunning = true;
    if (this._currentScene) {
      this._currentScene.attach();
    }
  }

  /**
   * Sets the current scene.
   * @param scene Scene to set
   */
  public setScene(scene: Scene) {
    if (this._currentScene != scene) {
      if (this._isRunning) {
        if (this._currentScene) {
          this._currentScene.detach();
        }
        this._currentScene = scene;
        if (this._currentScene) {
          this._currentScene.attach();
        }
      } else {
        this._currentScene = scene;
      }
    }
  }
}

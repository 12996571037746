import { Subscription } from "rxjs";
import { vec2 } from "gl-matrix";

import Scene from "~/base/scene";
import Entity from "~/base/entity";
import Screen from "~/base/injections/screen";
import ColumnEntity from "./column-entity";
import GameLogic, { GameState } from "./game-logic";
import BorderEntity from "./border-entity";
import provider from "./base/provider";
import PeerToPeer from "./base/p2p/peer-to-peer";

export default class FourWinsScene extends Scene {
  constructor() {
    super();

    const anchorEntity = new Entity();
    anchorEntity.localTransform.setPosition(0.5, 0.0);
    const boardEntity = new Entity();
    anchorEntity.addChild(boardEntity);
    this.addChild(anchorEntity);

    // responsiveness
    const w = GameLogic.COLS + 2;
    const h = GameLogic.ROWS + 2;
    const boardRatio = w / h;
    const screen = provider.resolve(Screen);
    screen.resize$.subscribe((screenInfo) => {
      const scale =
        screenInfo.ratio <= boardRatio
          ? 1 / w
          : 1 / ((GameLogic.COLS + 1) * screenInfo.ratio);

      anchorEntity.localTransform.setScale(scale);
      boardEntity.localTransform.setPosition(-(GameLogic.COLS / 2), 1);
    });

    // tiles
    Array(GameLogic.COLS)
      .fill(0)
      .forEach((_, i) => {
        const tileEntity = new ColumnEntity({
          column: i,
          rows: GameLogic.ROWS,
        });
        tileEntity.localTransform.setPosition(i, 0);
        boardEntity.addChild(tileEntity);
      });

    // border
    const borderEntity = new BorderEntity({
      leftTop: vec2.fromValues(0, 0),
      rightBottom: vec2.fromValues(GameLogic.COLS, GameLogic.ROWS),
      scale: 1 / 3,
    });
    boardEntity.addChild(borderEntity);

    // Dialogues
    const gameLogic = provider.resolve(GameLogic);
    const splashDiv = this.createSplashDialog();
    const theEndDiv = this.createTheEndDialog();
    let gameStateSubscription: Subscription | null = null;
    this.attach$.subscribe(() => {
      gameStateSubscription = gameLogic.gameState$.subscribe((gameState) => {
        switch (gameState) {
          case GameState.Splash:
            theEndDiv.remove();
            document.body.appendChild(splashDiv);
            break;

          case GameState.Game:
            splashDiv.remove();
            theEndDiv.remove();
            break;

          case GameState.TheEnd:
            splashDiv.remove();
            document.body.appendChild(theEndDiv);
            break;
        }
      });
    });
    this.detach$.subscribe(() => {
      splashDiv.remove();
      theEndDiv.remove();
    });
  }

  private createSplashDialog(): HTMLDivElement {
    const windowDiv = document.createElement("div");
    windowDiv.className = "modal-dialog";

    const header = document.createElement("h1");
    header.textContent = "Spiele jetzt 4-Gewinnt!";
    windowDiv.appendChild(header);

    const contentDiv = document.createElement("div");
    contentDiv.className = "content";
    windowDiv.appendChild(contentDiv);

    const text = document.createElement("h2");
    text.textContent = "Sende einfach den folgenden Link an einen Freund:";
    contentDiv.appendChild(text);

    const linkDiv = document.createElement("div");
    contentDiv.appendChild(linkDiv);

    const link = document.createElement("span");
    link.innerHTML = "<i>Verbinde...</i>";
    linkDiv.appendChild(link);

    const infoText = document.createElement("p");
    infoText.innerHTML =
      "<u>Hinweis:</u> Damit ihr gegeneinander spielen könnt, wird deinem Freund zum Verbindungsaufbau deine IP-Adresse mitgeteilt!";
    contentDiv.appendChild(infoText);

    const p2p = provider.resolve(PeerToPeer);
    p2p.peerOpen$.subscribe((id) => {
      const url = `${process.env.MY_URL}?id=${id}`;
      link.innerHTML = url;

      const copyButton = document.createElement("button");
      copyButton.className = "btn btn-ok";
      copyButton.textContent = "Kopieren";
      copyButton.title = "in Zwichenablage kopieren";
      copyButton.onclick = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = url;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        tempTextArea.remove();
      };
      linkDiv.appendChild(copyButton);
    });

    return windowDiv;
  }

  private createTheEndDialog(): HTMLDivElement {
    const gameLogic = provider.resolve(GameLogic);
    const windowDiv = document.createElement("div");
    windowDiv.className = "modal-dialog";

    const header = document.createElement("h1");
    header.textContent = "Game Over";
    windowDiv.appendChild(header);

    const contentDiv = document.createElement("div");
    contentDiv.className = "content";
    windowDiv.appendChild(contentDiv);

    const text = document.createElement("h2");
    contentDiv.appendChild(text);

    gameLogic.winner$.subscribe((winner) => {
      if (!winner) {
        text.textContent = "uhh - Das sieht nach einem Unentschieden aus!";
      } else if (winner === gameLogic.playerNumber) {
        text.textContent = "Super - Du hast gewonnen!";
      } else {
        text.textContent = "Schade - Du hast verloren!";
      }
    });

    const footerDiv = document.createElement("div");
    footerDiv.className = "footer";
    windowDiv.appendChild(footerDiv);

    const playAgainButton = document.createElement("button");
    playAgainButton.className = "btn btn-green";
    gameLogic.round$.subscribe(() => {
      playAgainButton.textContent = "Erneut spielen";
    });
    playAgainButton.onclick = () => {
      playAgainButton.textContent = "Warte...";
      gameLogic.playAgain();
    };
    footerDiv.appendChild(playAgainButton);

    const quitButton = document.createElement("button");
    quitButton.className = "btn btn-ok";
    quitButton.textContent = "Aufhören";
    quitButton.onclick = () => {
      gameLogic.reset();
    };
    footerDiv.appendChild(quitButton);

    return windowDiv;
  }
}

import { mat3 } from "gl-matrix";

import Material from "./material";
import { Uniform, UniformMatrix3fv } from "./uniform";
import Primitive from "./primitive";

export default class Model {
  private readonly _uniforms: Map<string, Uniform>;

  public get layer() {
    return this.options.layer;
  }

  public get material() {
    return this.options.material;
  }

  public get vertexArrayObject() {
    return this.options.primitive.vertexArrayObject;
  }

  public get uniforms() {
    return this._uniforms.values();
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: IModelOptions
  ) {
    this._uniforms = new Map<string, Uniform>();
  }

  public setUniform(uniform: Uniform) {
    this._uniforms.set(uniform.name, uniform);
  }

  public setModelViewMatrix(modelViewMatrix: mat3) {
    if (this.options.material.program.modelViewMatrixUniformName) {
      const uniform = this._uniforms.get(
        this.options.material.program.modelViewMatrixUniformName
      );
      if (uniform) {
        (<UniformMatrix3fv>uniform).value = modelViewMatrix;
      } else {
        this._uniforms.set(
          this.options.material.program.modelViewMatrixUniformName,
          new UniformMatrix3fv(
            this.options.material.program.modelViewMatrixUniformName,
            modelViewMatrix
          )
        );
      }
    }
  }

  public draw() {
    this._uniforms.forEach((x) => x.apply(this.material.program));
    this.gl.drawArrays(
      this.options.primitive.drawMode,
      this.options.primitive.firstVertex,
      this.options.primitive.vertexCount
    );
  }
}

export interface IModelOptions {
  layer: number;
  material: Material;
  primitive: Primitive;
}

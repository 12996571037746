export default class Shader {
  private _glShader: WebGLShader | null;

  public get glShader(): WebGLShader {
    if (!this._glShader) {
      this._glShader = this.compile();
    }
    return this._glShader;
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: IShaderOptions
  ) {
    this._glShader = null;
  }

  private compile(): WebGLShader {
    const shader = <WebGLShader>this.gl.createShader(this.options.type);
    console.assert(shader, "Creating the shader failed!");

    this.gl.shaderSource(shader, this.options.source);
    this.gl.compileShader(shader);
    console.assert(
      this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS),
      "Compiling the shader failed! " + this.gl.getShaderInfoLog(shader)
    );

    return shader;
  }
}

export interface IShaderOptions {
  source: string;
  type: number;
}

export default class Texture {
  private isLoaded: boolean;
  private _glTexture: WebGLTexture | null;

  public get glTexture() {
    if (!this._glTexture) {
      this._glTexture = this.load();
    }
    return this._glTexture;
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: ITextureOptions
  ) {
    this.isLoaded = false;
    this._glTexture = null;
  }

  public bind(): boolean {
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.glTexture);
    return this.isLoaded;
  }

  private load(): WebGLTexture {
    const texture = <WebGLTexture>this.gl.createTexture();
    console.assert(texture, "Creating the texture failed!");

    const that = this;
    const image = new Image();
    image.onload = function() {
      that.gl.bindTexture(that.gl.TEXTURE_2D, texture);
      that.gl.texImage2D(
        that.gl.TEXTURE_2D,
        0,
        that.gl.RGBA,
        that.gl.RGBA,
        that.gl.UNSIGNED_BYTE,
        image
      );
      if (that.isPowerOf2(image.width) && that.isPowerOf2(image.height)) {
        that.gl.generateMipmap(that.gl.TEXTURE_2D);
        that.gl.texParameteri(
          that.gl.TEXTURE_2D,
          that.gl.TEXTURE_MIN_FILTER,
          that.gl.LINEAR_MIPMAP_NEAREST
        );
      } else {
        that.gl.texParameteri(
          that.gl.TEXTURE_2D,
          that.gl.TEXTURE_MIN_FILTER,
          that.gl.LINEAR
        );
      }
      if (that.options.wrapMode) {
        switch (that.options.wrapMode) {
          case WrapMode.Repeat:
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_S,
              that.gl.REPEAT
            );
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_T,
              that.gl.REPEAT
            );
            break;
          case WrapMode.MirroredRepeat:
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_S,
              that.gl.MIRRORED_REPEAT
            );
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_T,
              that.gl.MIRRORED_REPEAT
            );
            break;
          case WrapMode.Clamp:
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_S,
              that.gl.CLAMP_TO_EDGE
            );
            that.gl.texParameteri(
              that.gl.TEXTURE_2D,
              that.gl.TEXTURE_WRAP_T,
              that.gl.CLAMP_TO_EDGE
            );
            break;
        }
      }
      // that.gl.texParameteri(
      //   that.gl.TEXTURE_2D,
      //   that.gl.TEXTURE_WRAP_S,
      //   that.gl.MIRRORED_REPEAT
      // );
      // that.gl.texParameteri(
      //   that.gl.TEXTURE_2D,
      //   that.gl.TEXTURE_WRAP_T,
      //   that.gl.MIRRORED_REPEAT
      // );
      that.isLoaded = true;
    };
    image.src = this.options.url;

    return texture;
  }

  private isPowerOf2(value: number): boolean {
    return (value & (value - 1)) == 0;
  }
}

export enum WrapMode {
  Repeat = 1,
  MirroredRepeat = 2,
  Clamp = 3,
}

export interface ITextureOptions {
  url: string;
  wrapMode?: WrapMode;
}

import provider from "../provider";
import Component from "../component";
import Renderer from "../rendering/renderer";
import Model, { IModelOptions } from "../rendering/model";
import { vec2, mat3 } from "gl-matrix";

export default class ModelComponent extends Component {
  public readonly model: Model;

  constructor(options: IModelComponentOptions) {
    super();

    const renderer = provider.resolve(Renderer);
    this.model = new Model(renderer.gl, options.modelOptions);
    const modelViewMatrix = mat3.create();
    const offset = options.anchor
      ? mat3.fromTranslation(
          mat3.create(),
          vec2.scale(vec2.create(), options.anchor, -1)
        )
      : null;

    this.attach$.subscribe(() => {
      renderer.addModel(this.model);
    });
    this.detach$.subscribe(() => {
      renderer.removeModel(this.model);
    });
    this.frameTick$.subscribe(() => {
      if (this.parent) {
        if (offset) {
          mat3.mul(modelViewMatrix, this.parent.worldTransform, offset);
          this.model.setModelViewMatrix(modelViewMatrix);
        } else {
          this.model.setModelViewMatrix(this.parent.worldTransform);
        }
      }
    });
  }
}

export interface IModelComponentOptions {
  modelOptions: IModelOptions;
  anchor?: vec2;
}

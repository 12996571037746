import Program from "./program";
import Buffer from "./buffer";
import VertexAttribute from "./vertex-attribute";

export default class VertexArrayObject {
  private _glVao: WebGLVertexArrayObject | null;

  public get glVao() {
    if (!this._glVao) {
      this._glVao = this.create();
    }
    return this._glVao;
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: IVertexArrayObjectOptions
  ) {
    this._glVao = null;
  }

  public bind() {
    this.gl.bindVertexArray(this.glVao);
  }

  private create(): WebGLVertexArrayObject {
    const vao = <WebGLVertexArrayObject>this.gl.createVertexArray();
    console.assert(vao, "Creating the VAO failed!");

    this.gl.bindVertexArray(vao);
    this.options.bufferData.forEach(data => {
      data.buffer.bind();
      data.attributes.forEach(attribute => {
        const location = this.options.program.getAttributeLocation(
          attribute.name
        );
        attribute.enable(this.gl, location);
      });
    });
    this.gl.bindVertexArray(null);

    return vao;
  }
}

export interface IBufferData {
  buffer: Buffer;
  attributes: VertexAttribute[];
}

export interface IVertexArrayObjectOptions {
  program: Program;
  bufferData: IBufferData[];
}

import { vec4, vec2 } from "gl-matrix";

import Entity from "~/base/entity";
import provider from "~/base/provider";
import Primitive from "~/base/rendering/primitive";
import Material from "~/base/rendering/material";
import ModelComponent from "~/base/components/model-component";
import { Uniform4fv } from "~/base/rendering/uniform";

import { SQUARE_PRIMITIVE_TOKEN, DOT_MATERIAL_TOKEN } from "./gl-stuff";
import GameLogic from "./game-logic";
import { animateNumber } from "~/base/animation/animation";
import { easeInOutQuad } from "~/base/animation/easing";
import { filter } from "rxjs/operators";

export default class HighlightEntity extends Entity {
  private readonly _anchorEntity: Entity;
  private readonly _tintUniform: Uniform4fv;
  private _visible: boolean;
  private _gameLogic: GameLogic;

  constructor(options: IHightlightOptions) {
    super();

    this._anchorEntity = new Entity();
    this._tintUniform = new Uniform4fv(
      "uTint",
      vec4.fromValues(1, 1, 1, 1)
    );
    this._visible = false;
    this._gameLogic = provider.resolve(GameLogic);

    // initial position
    this.localTransform.setPosition(0.5, GameLogic.ROWS - 0.5);

    // model
    const squarePrimitive = provider.resolve<Primitive>(SQUARE_PRIMITIVE_TOKEN);
    const dotMaterial = provider.resolve<Material>(DOT_MATERIAL_TOKEN);

    // add N model-components
    const N = 7;
    for (let i = 0; i < N; i++) {
      const offsetEntity = new Entity();
      offsetEntity.localTransform.setAngle((i * 2 * Math.PI) / N);
      offsetEntity.localTransform.setScale(0.125);

      const dotModelComponent = new ModelComponent({
        modelOptions: {
          layer: 1,
          material: dotMaterial,
          primitive: squarePrimitive,
        },
        anchor: vec2.fromValues(0.5, 3.25),
      });
      dotModelComponent.model.setUniform(this._tintUniform);
      offsetEntity.addComponent(ModelComponent, dotModelComponent);
      this._anchorEntity.addChild(offsetEntity);
    }

    // constant rotation
    this.frameTick$.subscribe((x) => {
      this._anchorEntity.localTransform.setAngle((x.timeSeconds * Math.PI) / 2);
    });

    this._gameLogic.playerColor$.subscribe((color) => {
      vec4.copy(this._tintUniform.value, color);
    });
    this._gameLogic.myTurn$
      .pipe(filter((myTurn) => !myTurn))
      .subscribe(() => this.hide());
  }

  public show(row: number) {
    if (this._gameLogic.myTurn && 0 <= row) {
      this._visible = true;
      this.localTransform.setPosition(0.5, row + 0.5);
      this._tintUniform.value[3] = 0;
      this.addChild(this._anchorEntity);

      animateNumber(0, 1, 0.3, easeInOutQuad, this.frameTick$).subscribe(
        (a) => (this._tintUniform.value[3] = a)
      );
    }
  }

  public hide() {
    this._visible = false;
    animateNumber(1, 0, 0.3, easeInOutQuad, this.frameTick$).subscribe(
      (a) => (this._tintUniform.value[3] = a),
      null,
      () => {
        if (!this._visible) {
          this.removeChild(this._anchorEntity);
        }
      }
    );
  }
}

export interface IHightlightOptions {
  column: number;
}

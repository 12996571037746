import { initializeGlStuff } from "./gl-stuff";
import Game from "~/base/game";
import FourWinsScene from "./four-wins-scene";
import provider from "~/base/provider";
import PeerToPeer, {
  ConnectDirection,
  IConnectionInfo,
} from "~/base/p2p/peer-to-peer";
import GameLogic, { GameState } from "./game-logic";

export default class FourWinsGame extends Game {
  constructor(parentHtmlElement: HTMLElement) {
    super(parentHtmlElement);
  }

  public run() {
    initializeGlStuff();

    // peer-js stuff
    const p2p = new PeerToPeer({
      host: process.env.PEERJS_HOST,
      port: process.env.PEERJS_PORT ? +process.env.PEERJS_PORT : undefined,
      path: process.env.PEERJS_PATH,
    });
    provider.registerInstance(PeerToPeer, p2p);
    let connection: IConnectionInfo | null = null;

    const gameLogic = new GameLogic();
    provider.registerInstance(GameLogic, gameLogic);

    // connection stuff
    gameLogic.reset$.subscribe(() => {
      connection?.connection.close();
      connection = null;
    });
    p2p.connectionOpen$.subscribe((x) => {
      if (gameLogic.gameState === GameState.Splash) {
        connection = x;
        gameLogic.start(x.direction == ConnectDirection.Outgoing);
      } else {
        x.connection.close();
      }
    });
    p2p.connectionClose$.subscribe((x) => {
      if (x === connection) {
        gameLogic.reset();
        alert("Your opponent has left the game!");
      }
    });

    // connect to remote id
    const params = new URLSearchParams(window.location.search);
    if (params.has("id")) {
      const remoteId = params.get("id");
      if (remoteId) {
        console.log("Connecting to: " + remoteId);
        p2p.connect(remoteId);
      }
    }

    const scene = new FourWinsScene();
    this.setScene(scene);

    super.run();
  }
}

import provider from "~/base/provider";
import Renderer from "~/base/rendering/renderer";
import Shader from "~/base/rendering/shader";
import Program from "~/base/rendering/program";
import Buffer from "~/base/rendering/buffer";
import VertexArrayObject from "~/base/rendering/vertex-array-object";
import VertexAttribute from "~/base/rendering/vertex-attribute";
import Primitive, { PrimitiveType } from "~/base/rendering/primitive";
import Texture, { WrapMode } from "~/base/rendering/texture";
import Material from "~/base/rendering/material";

import DEFAULT_VERTEX_SHADER_SOURCE from "./shader/default.vert";
import DEFAULT_FRAGMENT_SHADER_SOURCE from "./shader/default.frag";
import BOARD_VERTEX_SHADER_SOURCE from "./shader/board.vert";
import BOARD_FRAGMENT_SHADER_SOURCE from "./shader/board.frag";
import TILE_IMAGE_URL from "@/tile.png";
import BORDER_IMAGE_URL from "@/border.png";
import METAL_IMAGE_URL from "@/metal.png";
import ARROW_IMAGE_URL from "@/arrow.png";
import CROSS_IMAGE_URL from "@/cross-o.png";
import DOT_IMAGE_URL from "@/dot.png";
import COIN_IMAGE_URL from "@/coin.png";

export const DEFAULT_PROGRAM_TOKEN = Symbol();
export const SQUARE_PRIMITIVE_TOKEN = Symbol();
export const TILE_MATERIAL_TOKEN = Symbol();
export const BORDER_MATERIAL_TOKEN = Symbol();
export const ARROW_MATERIAL_TOKEN = Symbol();
export const CROSS_MATERIAL_TOKEN = Symbol();
export const DOT_MATERIAL_TOKEN = Symbol();
export const COIN_MATRIAL_TOKEN = Symbol();

export function initializeGlStuff() {
  const renderer = provider.resolve(Renderer);
  const gl = renderer.gl;

  const defaultVertexShader = new Shader(gl, {
    type: gl.VERTEX_SHADER,
    source: DEFAULT_VERTEX_SHADER_SOURCE,
  });
  const defaultFragmentShader = new Shader(gl, {
    type: gl.FRAGMENT_SHADER,
    source: DEFAULT_FRAGMENT_SHADER_SOURCE,
  });
  const defaultProgram = new Program(gl, {
    vertexShader: defaultVertexShader,
    fragmentShader: defaultFragmentShader,
    modelViewMatrixUniformName: "uModelViewMatrix",
    projectionMatrixUniformName: "uProjectionMatrix",
  });
  const boardVertexShader = new Shader(gl, {
    type: gl.VERTEX_SHADER,
    source: BOARD_VERTEX_SHADER_SOURCE,
  });
  const boardFragmentShader = new Shader(gl, {
    type: gl.FRAGMENT_SHADER,
    source: BOARD_FRAGMENT_SHADER_SOURCE,
  });
  const boardProgram = new Program(gl, {
    vertexShader: boardVertexShader,
    fragmentShader: boardFragmentShader,
    modelViewMatrixUniformName: "uModelViewMatrix",
    projectionMatrixUniformName: "uProjectionMatrix",
  });

  const squarePositionBuffer = new Buffer(gl, {
    data: new Float32Array([0.0, 1.0, 1.0, 1.0, 0.0, 0.0, 1.0, 0.0]),
  });
  const squareTexCoordBuffer = new Buffer(gl, {
    data: new Float32Array([0.0, 1.0, 1.0, 1.0, 0.0, 0.0, 1.0, 0.0]),
  });
  const squareVao = new VertexArrayObject(gl, {
    program: defaultProgram,
    bufferData: [
      {
        buffer: squarePositionBuffer,
        attributes: [
          new VertexAttribute({
            name: "aVertexPosition",
            size: 2,
            stride: 0,
            offset: 0,
          }),
        ],
      },
      {
        buffer: squareTexCoordBuffer,
        attributes: [
          new VertexAttribute({
            name: "aTextureCoord",
            size: 2,
            stride: 0,
            offset: 0,
          }),
        ],
      },
    ],
  });
  const squarePrimitive = new Primitive(gl, {
    vertexArrayObject: squareVao,
    primitiveType: PrimitiveType.TriangleStrip,
    vertexCount: 4,
  });

  // Tile
  const metalTexture = new Texture(gl, {
    url: METAL_IMAGE_URL,
    wrapMode: WrapMode.MirroredRepeat,
  });
  const tileTexture = new Texture(gl, {
    url: TILE_IMAGE_URL,
  });
  const tileMaterial = new Material(gl, {
    program: boardProgram,
    textures: [
      {
        texture: tileTexture,
        samplerUniformName: "uSampler",
      },
      {
        texture: metalTexture,
        samplerUniformName: "uSampler2",
      },
    ],
  });

  // border
  const borderTexture = new Texture(gl, {
    url: BORDER_IMAGE_URL,
  });
  const borderMaterial = new Material(gl, {
    program: boardProgram,
    textures: [
      {
        texture: borderTexture,
        samplerUniformName: "uSampler",
      },
    ],
  });

  // Arrow
  const arrowTexture = new Texture(gl, {
    url: ARROW_IMAGE_URL,
  });
  const arrowMaterial = new Material(gl, {
    program: defaultProgram,
    textures: [
      {
        texture: arrowTexture,
        samplerUniformName: "uSampler",
      },
    ],
  });

  // Cross
  const crossTexture = new Texture(gl, {
    url: CROSS_IMAGE_URL,
  });
  const crossMaterial = new Material(gl, {
    program: defaultProgram,
    textures: [
      {
        texture: crossTexture,
        samplerUniformName: "uSampler",
      },
    ],
  });

  // Dot
  const dotTexture = new Texture(gl, {
    url: DOT_IMAGE_URL,
  });
  const dotMaterial = new Material(gl, {
    program: defaultProgram,
    textures: [
      {
        texture: dotTexture,
        samplerUniformName: "uSampler",
      },
    ],
  });

  // Coin
  const coinTexture = new Texture(gl, {
    url: COIN_IMAGE_URL,
  });
  const coinMaterial = new Material(gl, {
    program: defaultProgram,
    textures: [
      {
        texture: coinTexture,
        samplerUniformName: "uSampler",
      },
    ],
  });

  provider.registerInstance(DEFAULT_PROGRAM_TOKEN, defaultProgram);
  provider.registerInstance(SQUARE_PRIMITIVE_TOKEN, squarePrimitive);
  provider.registerInstance(TILE_MATERIAL_TOKEN, tileMaterial);
  provider.registerInstance(BORDER_MATERIAL_TOKEN, borderMaterial);
  provider.registerInstance(ARROW_MATERIAL_TOKEN, arrowMaterial);
  provider.registerInstance(CROSS_MATERIAL_TOKEN, crossMaterial);
  provider.registerInstance(DOT_MATERIAL_TOKEN, dotMaterial);
  provider.registerInstance(COIN_MATRIAL_TOKEN, coinMaterial);
}

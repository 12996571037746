import Entity from "~/base/entity";
import TileEntity from "./tile-entity";
import MouseInputComponent from "~/base/components/mouse-input-component";
import Rectangle from "~/base/physics/rectangle";
import ArrowEntity from "./arrow-entity";
import CoinEntity from "./coin-entity";
import provider from "~/base/provider";
import GameLogic, { GameState } from "./game-logic";
import { filter } from "rxjs/operators";
import HighlightEntity from "./highlight-entity";

export default class ColumnEntity extends Entity {
  private _coins: number;

  public get isFull() {
    return this.options.rows <= this._coins;
  }

  constructor(private readonly options: IColumnOptions) {
    super();

    this._coins = 0;

    // add one tile for each row
    Array(options.rows)
      .fill(0)
      .forEach((_, i) => {
        const tileEntity = new TileEntity();
        tileEntity.localTransform.setPosition(0, i);
        this.addChild(tileEntity);
      });

    // add arrow above column
    const arrowEntity = new ArrowEntity({ column: options.column });
    this.addChild(arrowEntity);

    // highlight
    const highlightEntity = new HighlightEntity({ column: options.column });
    this.addChild(highlightEntity);

    // logic stuff
    const gameLogic = provider.resolve(GameLogic);
    gameLogic.gameState$
      .pipe(filter((x) => x === GameState.Game))
      .subscribe(() => {
        this._coins = 0;
        [...this.children]
          .filter((x) => x instanceof CoinEntity)
          .forEach((child) => this.removeChild(child));
      });
    gameLogic.dropCoin$
      .pipe(filter((x) => x.column === options.column))
      .subscribe((x) => {
        this._coins++;
        const coin = new CoinEntity({
          column: options.column,
          row: options.rows - this._coins,
          playerNumber: x.playerNumber,
        });
        this.addChild(coin);
        coin.drop();
      });

    // mouse input
    const mouseInputComponent = new MouseInputComponent({
      shape: Rectangle.fromValues(0, 0, 1, options.rows),
    });
    mouseInputComponent.in$.subscribe(() => {
      arrowEntity.goof();
      highlightEntity.show(options.rows - this._coins - 1);
    });
    mouseInputComponent.out$.subscribe(() => {
      highlightEntity.hide();
    });
    mouseInputComponent.click$.subscribe(() => {
      if (this._coins < options.rows) {
        gameLogic.dropCoin(options.column);
      }
    });
    this.addComponent(MouseInputComponent, mouseInputComponent);
  }
}

export interface IColumnOptions {
  column: number;
  rows: number;
}

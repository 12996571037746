import { BehaviorSubject, Subject, merge } from "rxjs";
import { map, filter } from "rxjs/operators";
import { vec4 } from "gl-matrix";

import PeerToPeer from "~/base/p2p/peer-to-peer";
import provider from "~/base/provider";
import { allEqual, range, cross } from "~/base/common/array-helper";

export default class GameLogic {
  public static readonly COLS = 7;
  public static readonly ROWS = 6;
  public static readonly BOARD_COLOR = vec4.fromValues(0.9, 0.871, 0.87, 1); // F5D7D5 (0.961, 0.843, 0.835, 1)
  public static readonly COLOR_PLAYER_1 = vec4.fromValues(
    0.863,
    0.427,
    0.396,
    1
  ); // DC6D65
  public static readonly COLOR_PLAYER_2 = vec4.fromValues(
    0.522,
    0.596,
    0.671,
    1
  ); // 8598AB

  public static getPlayerColor(playerNumber: number) {
    return playerNumber === 1
      ? GameLogic.COLOR_PLAYER_1
      : GameLogic.COLOR_PLAYER_2;
  }

  private _p2p: PeerToPeer;
  private _coins: number[][];
  private _gameState$: BehaviorSubject<GameState>;
  private _playerNumber$: BehaviorSubject<number>;
  private _round$: BehaviorSubject<number>;
  private _localWantsToPlayRound$: BehaviorSubject<number>;
  private _remoteWantsToPlayRound$: BehaviorSubject<number>;
  private _myTurn$: BehaviorSubject<boolean>;
  private _dropCoin$: Subject<IDropCoinInfo>;
  private _winner$: Subject<number>;
  private _pointsPlayer1: BehaviorSubject<number>;
  private _pointsPlayer2: BehaviorSubject<number>;
  private _reset$: Subject<any>;

  public get gameState$() {
    return this._gameState$;
  }

  public get gameState() {
    return this._gameState$.value;
  }

  public get playerNumber$() {
    return this._playerNumber$;
  }

  public get playerNumber() {
    return this._playerNumber$.value;
  }

  public get round$() {
    return this._round$.asObservable();
  }

  public get myTurn$() {
    return this._myTurn$.asObservable();
  }

  public get myTurn() {
    return this._myTurn$.value;
  }

  public get dropCoin$() {
    return this._dropCoin$.asObservable();
  }

  public get winner$() {
    return this._winner$.asObservable();
  }

  public get pointsPlayer1() {
    return this._pointsPlayer1.asObservable();
  }

  public get pointsPlayer2() {
    return this._pointsPlayer2.asObservable();
  }

  public get reset$() {
    return this._reset$.asObservable();
  }

  public get playerColor$() {
    return this._playerNumber$.pipe(map(GameLogic.getPlayerColor));
  }

  public get playerColor() {
    return GameLogic.getPlayerColor(this.playerNumber);
  }

  constructor() {
    this._coins = Array(GameLogic.COLS)
      .fill([])
      .map(() => []);
    this._gameState$ = new BehaviorSubject<GameState>(GameState.Splash);
    this._playerNumber$ = new BehaviorSubject(0);
    this._round$ = new BehaviorSubject(0);
    this._localWantsToPlayRound$ = new BehaviorSubject(0);
    this._remoteWantsToPlayRound$ = new BehaviorSubject(0);
    this._myTurn$ = new BehaviorSubject<boolean>(false);
    this._dropCoin$ = new Subject<IDropCoinInfo>();
    this._winner$ = new Subject<number>();
    this._pointsPlayer1 = new BehaviorSubject<number>(0);
    this._pointsPlayer2 = new BehaviorSubject<number>(0);

    this._reset$ = new Subject<any>();
    this._p2p = provider.resolve(PeerToPeer);

    this._p2p.receive$
      .pipe(map((x) => x.data as IMessage))
      .subscribe((data) => {
        switch (data.messageType) {
          case MessageType.DropCoin:
            console.assert(!this._myTurn$.value, "invalid opponent move!");
            console.assert(
              data.dropCoinInfo,
              "received invalid [DropCoin] message!"
            );
            const dropCoinInfo = <IDropCoinInfo>data.dropCoinInfo;
            if (!this.move(dropCoinInfo.column, dropCoinInfo.playerNumber)) {
              this._myTurn$.next(true);
            }
            break;

          case MessageType.LetsPlay:
            console.assert(data.round, "received invalid [LetsPlay] message!");
            this._remoteWantsToPlayRound$.next(data.round);
            break;
        }
      });

    this._localWantsToPlayRound$.subscribe((x) =>
      console.log("LOCAL WANTS TO PLAY ROUND " + x)
    );
    this._remoteWantsToPlayRound$.subscribe((x) =>
      console.log("REMOTE WANTS TO PLAY ROUND " + x)
    );

    merge(this._localWantsToPlayRound$, this._remoteWantsToPlayRound$)
      .pipe(
        filter(() => !!this._localWantsToPlayRound$.value),
        filter(
          () =>
            this._localWantsToPlayRound$.value ===
            this._remoteWantsToPlayRound$.value
        )
      )
      .subscribe(() => {
        console.log(
          `PLAY AGAIN! ${this._localWantsToPlayRound$.value} / ${this._remoteWantsToPlayRound$.value}`
        );
        this._coins = Array(GameLogic.COLS)
          .fill([])
          .map(() => []);
        this._round$.next(this._round$.value + 1);
        this._gameState$.next(GameState.Game);
        this._myTurn$.next((this._round$.value + this.playerNumber) % 2 === 0);
      });
  }

  public start(myTurn: boolean) {
    console.assert(0 < this._p2p.connections.length, "No opponent connected!");
    this._round$.next(1);
    this._gameState$.next(GameState.Game);
    this._playerNumber$.next(myTurn ? 1 : 2);
    this._myTurn$.next(myTurn);
  }

  public dropCoin(column: number) {
    if (!this._myTurn$.value) {
      return;
    }
    console.assert(
      0 <= column && column < GameLogic.COLS,
      "column out of range!"
    );
    if (this._coins[column].length < GameLogic.ROWS) {
      this._myTurn$.next(false);
      this.move(column, this.playerNumber);

      console.assert(
        0 < this._p2p.connections.length,
        "No opponent connected!"
      );
      this._p2p.broadcast<IMessage>({
        messageType: MessageType.DropCoin,
        round: this._round$.value,
        dropCoinInfo: {
          column,
          playerNumber: this.playerNumber,
        },
      });
    }
  }

  public playAgain() {
    const nextRoundNumber = this._round$.value + 1;
    this._localWantsToPlayRound$.next(nextRoundNumber);
    this._p2p.broadcast<IMessage>({
      messageType: MessageType.LetsPlay,
      round: nextRoundNumber,
    });
  }

  public reset() {
    this._coins = Array(GameLogic.COLS)
      .fill([])
      .map(() => []);
    this._gameState$.next(GameState.Splash);
    this._pointsPlayer1.next(0);
    this._pointsPlayer2.next(0);
    this._playerNumber$.next(0);
    this._round$.next(0);
    this._myTurn$.next(false);
    this._reset$.next();
  }

  /**
   * Performs a move and returns the number of the Player who won!
   * @param column Column to drop the coin
   * @param playerNumber Number of the player who drops the coin
   */
  private move(column: number, playerNumber: number): number {
    this._coins[column].push(playerNumber);
    this._dropCoin$.next({
      column,
      playerNumber,
    });

    // is there a winner?
    const winner = this.checkWin();
    if (winner) {
      this._winner$.next(winner);
      if (winner === 1) {
        this._pointsPlayer1.next(this._pointsPlayer1.value + 1);
      } else {
        this._pointsPlayer2.next(this._pointsPlayer2.value + 1);
      }
      this._gameState$.next(GameState.TheEnd);
    }

    // is the board completely filled?
    const boardFull = this._coins.every(
      (col) => GameLogic.ROWS <= col.length && col.every((cell) => !!cell)
    );
    if (boardFull) {
      this._winner$.next(0);
      this._gameState$.next(GameState.TheEnd);
    }

    return winner;
  }

  private checkWin(): number {
    // horizontal
    const winsHorizontal = cross(
      range(GameLogic.COLS - 3),
      range(GameLogic.ROWS)
    )
      .map(([col, row]) =>
        allEqual(range(4).map((coin) => this._coins[col + coin][row]))
          ? this._coins[col][row]
          : 0
      )
      .filter((x) => !!x);

    // vertical
    const winsVertical = cross(range(GameLogic.COLS), range(GameLogic.ROWS - 3))
      .map(([col, row]) =>
        allEqual(range(4).map((coin) => this._coins[col][row + coin]))
          ? this._coins[col][row]
          : 0
      )
      .filter((x) => !!x);

    // top right to bottom left
    const winsTrbl = cross(range(GameLogic.COLS - 3), range(GameLogic.ROWS - 3))
      .map(([col, row]) =>
        allEqual(range(4).map((coin) => this._coins[col + coin][row + coin]))
          ? this._coins[col][row]
          : 0
      )
      .filter((x) => !!x);

    // top left to bottom right
    const winsTlbr = cross(
      range(GameLogic.COLS - 3, 3),
      range(GameLogic.ROWS - 3)
    )
      .map(([col, row]) =>
        allEqual(range(4).map((coin) => this._coins[col - coin][row + coin]))
          ? this._coins[col][row]
          : 0
      )
      .filter((x) => !!x);

    // concat all wins
    const wins = winsHorizontal.concat(
      ...winsVertical,
      ...winsTrbl,
      ...winsTlbr
    );

    if (wins.length) {
      console.warn("WIN!!!");
      return wins[0];
    } else {
      return 0;
    }
  }
}

export enum GameState {
  Splash,
  Game,
  TheEnd,
}

export interface IDropCoinInfo {
  column: number;
  playerNumber: number;
}

enum MessageType {
  LetsPlay = 1,
  DropCoin = 2,
}

interface IMessage {
  messageType: MessageType;
  round: number;
  dropCoinInfo?: IDropCoinInfo;
}

import { Uniform4fv, Uniform1ui } from "~/base/rendering/uniform";
import Entity from "~/base/entity";
import provider from "~/base/provider";
import Primitive from "~/base/rendering/primitive";
import Material from "~/base/rendering/material";
import ModelComponent from "~/base/components/model-component";
import GameLogic from "./game-logic";

import { SQUARE_PRIMITIVE_TOKEN, BORDER_MATERIAL_TOKEN } from "./gl-stuff";
import { vec2 } from "gl-matrix";
import { IModelOptions } from "~/base/rendering/model";

export default class BorderEntity extends Entity {
  constructor(options: IBorderOptions) {
    super();

    this.localTransform.setScale(options.scale);

    // model
    const squarePrimitive = provider.resolve<Primitive>(SQUARE_PRIMITIVE_TOKEN);
    const borderMaterial = provider.resolve<Material>(BORDER_MATERIAL_TOKEN);

    const tintUniform = new Uniform4fv("uTint", GameLogic.BOARD_COLOR);
    const tilingUniform = new Uniform1ui("uTiling", 3);
    const modelOptions: IModelOptions = {
      layer: 1,
      material: borderMaterial,
      primitive: squarePrimitive,
    };

    const leftTop = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(1, 1),
    });
    leftTop.model.setUniform(tintUniform);
    leftTop.model.setUniform(new Uniform1ui("uTile", 0));
    leftTop.model.setUniform(tilingUniform);
    this.addComponent(ModelComponent, leftTop);

    const rightTop = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(-options.rightBottom[0] / options.scale, 1),
    });
    rightTop.model.setUniform(tintUniform);
    rightTop.model.setUniform(new Uniform1ui("uTile", 2));
    rightTop.model.setUniform(tilingUniform);
    this.addComponent(ModelComponent, rightTop);

    const leftBottom = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(1, -options.rightBottom[1] / options.scale),
    });
    leftBottom.model.setUniform(tintUniform);
    leftBottom.model.setUniform(new Uniform1ui("uTile", 6));
    leftBottom.model.setUniform(tilingUniform);
    this.addComponent(ModelComponent, leftBottom);

    const rightBottom = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(
        -options.rightBottom[0] / options.scale,
        -options.rightBottom[1] / options.scale
      ),
    });
    rightBottom.model.setUniform(tintUniform);
    rightBottom.model.setUniform(new Uniform1ui("uTile", 8));
    rightBottom.model.setUniform(tilingUniform);
    this.addComponent(ModelComponent, rightBottom);

    const leftEntity = new Entity();
    leftEntity.localTransform.setScale(
      1,
      options.rightBottom[1] / options.scale
    );
    this.addChild(leftEntity);
    const left = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(1, 0),
    });
    left.model.setUniform(tintUniform);
    left.model.setUniform(new Uniform1ui("uTile", 3));
    left.model.setUniform(tilingUniform);
    leftEntity.addComponent(ModelComponent, left);

    const topEntity = new Entity();
    topEntity.localTransform.setScale(
      options.rightBottom[0] / options.scale,
      1
    );
    this.addChild(topEntity);
    const top = new ModelComponent({
      modelOptions,
      anchor: vec2.fromValues(0, 1),
    });
    top.model.setUniform(tintUniform);
    top.model.setUniform(new Uniform1ui("uTile", 1));
    top.model.setUniform(tilingUniform);
    topEntity.addComponent(ModelComponent, top);

    const rightEntity = new Entity();
    rightEntity.localTransform.setPosition(
      options.rightBottom[0] / options.scale,
      0
    );
    rightEntity.localTransform.setScale(
      1,
      options.rightBottom[1] / options.scale
    );
    this.addChild(rightEntity);
    const right = new ModelComponent({
      modelOptions,
    });
    right.model.setUniform(tintUniform);
    right.model.setUniform(new Uniform1ui("uTile", 5));
    right.model.setUniform(tilingUniform);
    rightEntity.addComponent(ModelComponent, right);

    const bottomEntity = new Entity();
    bottomEntity.localTransform.setPosition(
      0,
      options.rightBottom[1] / options.scale
    );
    bottomEntity.localTransform.setScale(
      options.rightBottom[0] / options.scale,
      1
    );
    this.addChild(bottomEntity);
    const bottom = new ModelComponent({
      modelOptions
    });
    bottom.model.setUniform(tintUniform);
    bottom.model.setUniform(new Uniform1ui("uTile", 7));
    bottom.model.setUniform(tilingUniform);
    bottomEntity.addComponent(ModelComponent, bottom);
  }
}

export interface IBorderOptions {
  leftTop: vec2;
  rightBottom: vec2;
  scale: number;
}

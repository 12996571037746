import { vec2 } from "gl-matrix";
import Shape from "./shape";

export default class Rectangle extends Shape {
  private constructor(public readonly p1: vec2, public readonly p2: vec2) {
    super();
  }

  public containsPoint(p: vec2): boolean {
    return (
      this.p1[0] <= p[0] &&
      p[0] <= this.p2[0] &&
      this.p1[1] <= p[1] &&
      p[1] <= this.p2[1]
    );
  }

  public static fromValues(x1: number, y1: number, x2: number, y2: number) {
    return new Rectangle(
      vec2.fromValues(Math.min(x1, x2), Math.min(y1, y2)),
      vec2.fromValues(Math.max(x1, x2), Math.max(y1, y2))
    );
  }
}

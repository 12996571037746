import { mat3 } from "gl-matrix";

import Program from "./program";
import Material from "./material";
import Model from "./model";
import VertexArrayObject from "./vertex-array-object";
import { normalOrthogonalProjection } from "../common/matrix-stuff";

export default class Renderer {
  private readonly _layers: ILayer[];
  private _projectionMatrix: mat3;

  public readonly gl: WebGL2RenderingContext;
  public readonly view: HTMLCanvasElement;

  public get isWorking() {
    return !!this.gl;
  }

  constructor(width: number, height: number) {
    this.view = document.createElement("canvas");
    this.view.width = width;
    this.view.height = height;

    this.gl = <WebGL2RenderingContext>(
      this.view.getContext("webgl2", { alpha: false, antialias: false })
    );
    console.assert(this.gl, "Creating the Web-GL context failed!");
    if (this.gl) {
      this.gl.enable(this.gl.BLEND);
      this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);
    }
    this._layers = [];
    this._projectionMatrix = normalOrthogonalProjection(width, height);
  }

  public resize(width: number, height: number) {
    this.view.width = width;
    this.view.height = height;
    this._projectionMatrix = normalOrthogonalProjection(width, height);
    this.gl.viewport(0, 0, width, height);
  }

  public render() {
    this.gl.clearColor(0.98, 0.99, 1.0, 1.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    let currentVao: VertexArrayObject | null = null;

    this._layers
      .filter((x) => !!x)
      .forEach((layer) => {
        layer.programNodes.forEach((programNode) => {
          programNode.program.use();
          programNode.program.setProjectionMatrix(this._projectionMatrix);
          programNode.materialNodes.forEach((materialNode) => {
            materialNode.material.use();
            materialNode.vertexArrayObjectNodes.forEach((vaoNode) => {
              if (currentVao !== vaoNode.vertexArrayObject) {
                currentVao = vaoNode.vertexArrayObject;
                vaoNode.vertexArrayObject.bind();
              }
              vaoNode.models.forEach((drawCallNode) => {
                drawCallNode.model.draw();
              });
            });
          });
        });
      });
  }

  public addModel(model: Model) {
    const layer = this.getLayer(model.layer);
    const programNode = this.getProgramNode(layer, model.material.program);
    const materialNode = this.getMaterialNode(programNode, model.material);
    const vertexArrayObjectNode = this.getVertexArrayObjectNode(
      materialNode,
      model.vertexArrayObject
    );

    vertexArrayObjectNode.models.set(model, {
      model: model,
    });
  }

  public removeModel(model: Model) {
    const layer = this.getLayer(model.layer);
    const programNode = this.getProgramNode(layer, model.material.program);
    const materialNode = this.getMaterialNode(programNode, model.material);
    const vertexArrayObjectNode = this.getVertexArrayObjectNode(
      materialNode,
      model.vertexArrayObject
    );

    vertexArrayObjectNode.models.delete(model);
  }

  private getLayer(layer: number): ILayer {
    if (!this._layers[layer]) {
      this._layers[layer] = {
        programNodes: new Map<Program, IProgramNode>(),
      };
    }
    return this._layers[layer];
  }

  private getProgramNode(layer: ILayer, program: Program): IProgramNode {
    if (layer.programNodes.has(program)) {
      return <IProgramNode>layer.programNodes.get(program);
    } else {
      const programNode = {
        program,
        materialNodes: new Map<Material, IMaterialNode>(),
      };
      layer.programNodes.set(program, programNode);
      return programNode;
    }
  }

  private getMaterialNode(
    programNode: IProgramNode,
    material: Material
  ): IMaterialNode {
    return (
      programNode.materialNodes.get(material) ||
      this.createMaterialNode(programNode, material)
    );
  }

  private createMaterialNode(
    programNode: IProgramNode,
    material: Material
  ): IMaterialNode {
    const materialNode: IMaterialNode = {
      material,
      vertexArrayObjectNodes: new Map<
        VertexArrayObject,
        IVertexArrayObjectNode
      >(),
    };
    programNode.materialNodes.set(material, materialNode);
    return materialNode;
  }

  private getVertexArrayObjectNode(
    materialNode: IMaterialNode,
    vertexArrayObject: VertexArrayObject
  ): IVertexArrayObjectNode {
    return (
      materialNode.vertexArrayObjectNodes.get(vertexArrayObject) ||
      this.createVertexArrayObjectNode(materialNode, vertexArrayObject)
    );
  }

  private createVertexArrayObjectNode(
    materialNode: IMaterialNode,
    vertexArrayObject: VertexArrayObject
  ): IVertexArrayObjectNode {
    const vertexArrayObjectNode: IVertexArrayObjectNode = {
      vertexArrayObject,
      models: new Map<Model, IModelNode>(),
    };
    materialNode.vertexArrayObjectNodes.set(
      vertexArrayObject,
      vertexArrayObjectNode
    );
    return vertexArrayObjectNode;
  }
}

interface IModelNode {
  model: Model;
}

interface IVertexArrayObjectNode {
  vertexArrayObject: VertexArrayObject;
  models: Map<Model, IModelNode>;
}

interface IMaterialNode {
  material: Material;
  vertexArrayObjectNodes: Map<VertexArrayObject, IVertexArrayObjectNode>;
}

interface IProgramNode {
  program: Program;
  materialNodes: Map<Material, IMaterialNode>;
}

interface ILayer {
  programNodes: Map<Program, IProgramNode>;
}

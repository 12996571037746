export default class Buffer {
  private _glBuffer: WebGLBuffer | null;

  public get glBuffer(): WebGLBuffer {
    if (!this._glBuffer) {
      this._glBuffer = this.create();
    }
    return this._glBuffer;
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: IBufferOptions
  ) {
    this._glBuffer = null;
  }

  private create(): WebGLBuffer {
    const buffer = <WebGLBuffer>this.gl.createBuffer();
    console.assert(buffer, "Creating the buffer failed!");

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      this.options.data,
      this.gl.STATIC_DRAW
    );

    return buffer;
  }

  public bind() {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.glBuffer);
  }
}

export interface IBufferOptions {
  data: Float32Array;
}

import Texture from "./texture";
import Program from "./program";

export default class Material {
  public get program() {
    return this.options.program;
  }

  constructor(
    private readonly gl: WebGL2RenderingContext,
    private readonly options: IMaterialOptions
  ) {}

  public use(): boolean {
    return (
      this.options.textures?.reduce((acc, cur, index) => {
        this.gl.activeTexture(this.gl.TEXTURE0 + index);
        acc = acc && cur.texture.bind();
        if (acc) {
          this.gl.uniform1i(
            this.options.program.getUniformLocation(cur.samplerUniformName),
            index
          );
        }
        return acc;
      }, <boolean>true) || true
    );
  }
}

export interface ITexture {
  texture: Texture;
  samplerUniformName: string;
}

export interface IMaterialOptions {
  program: Program;
  textures?: ITexture[];
}

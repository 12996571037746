import "./styles.css";
import { withLatestFrom } from "rxjs/operators";

import FourWinsGame from "./four-wins-game";
import provider from "./base/provider";
import GameLogic from "./game-logic";
import Screen from "./base/injections/screen";

import IMPRESSUM from "./impressum.txt";

console.log("env: ", process.env.NODE_ENV);
console.log("peerjs-host: ", process.env.PEERJS_HOST);
console.log("peerjs-port: ", process.env.PEERJS_PORT);
console.log("peerjs-path: ", process.env.PEERJS_PATH);

let legalNoticeOpen = false;
let cookieBannerVisible = false;

window.onload = function() {
  // container
  const containerDiv = document.createElement("div");
  containerDiv.className = "container";
  document.body.appendChild(containerDiv);

  // points
  const pointsContainer = document.createElement("div");
  pointsContainer.className = "points";
  containerDiv.append(pointsContainer);

  const pointsPlayer1 = document.createElement("span");
  pointsPlayer1.textContent = "0";
  pointsPlayer1.style.color = `rgb(
    ${GameLogic.COLOR_PLAYER_1[0] * 255},
    ${GameLogic.COLOR_PLAYER_1[1] * 255},
    ${GameLogic.COLOR_PLAYER_1[2] * 255}
    )`;
  pointsContainer.appendChild(pointsPlayer1);

  const pointsDivider = document.createElement("span");
  pointsDivider.innerHTML = "&nbsp;:&nbsp;";
  pointsContainer.appendChild(pointsDivider);

  const pointsPlayer2 = document.createElement("span");
  pointsPlayer2.textContent = "0";
  pointsPlayer2.style.color = `rgb(
    ${GameLogic.COLOR_PLAYER_2[0] * 255},
    ${GameLogic.COLOR_PLAYER_2[1] * 255},
    ${GameLogic.COLOR_PLAYER_2[2] * 255}
    )`;
  pointsContainer.appendChild(pointsPlayer2);

  const whosTurn = document.createElement("p");
  whosTurn.textContent = "Hallo Welt!";
  pointsContainer.appendChild(whosTurn);

  // game
  const gameDiv = document.createElement("div");
  gameDiv.className = "game";
  containerDiv.appendChild(gameDiv);

  // advertising
  const adDiv = document.createElement("div");
  adDiv.className = "advertising";
  containerDiv.appendChild(adDiv);

  // const allowCookieValue = getCookieValue("ALLOW_COOKIES");
  // if (!allowCookieValue) {
  //   showCookieBanner(adDiv);
  // } else if (allowCookieValue === "ALL") {
  //   activateAdsbygoogle();
  // }

  // footer
  const footerDiv = document.createElement("div");
  footerDiv.className = "footer";
  footerDiv.innerHTML = "<span>&copy;Tobias Schmidt, 2020&nbsp;|&nbsp;</span>";
  containerDiv.appendChild(footerDiv);
  createLegalNoticeButton(footerDiv);
  // footerDiv.append(" | ");
  // const cookieSettingsButton = createCookieSettingsButton();
  // cookieSettingsButton.onclick = () => {
  //   showCookieBanner(adDiv);
  // };
  // footerDiv.appendChild(cookieSettingsButton);

  const game = new FourWinsGame(gameDiv);
  if (game.rendererIsWorking) {
    game.run();

    const gameLogic = provider.resolve(GameLogic);
    gameLogic.myTurn$
      .pipe(withLatestFrom(gameLogic.playerNumber$))
      .subscribe(([myTurn, playerNumber]) => {
        if (playerNumber === 0) {
          pointsPlayer1.className = "";
          pointsPlayer2.className = "";
          whosTurn.textContent = "Hallo Welt!";
        } else {
          if (
            (myTurn && playerNumber === 1) ||
            (!myTurn && playerNumber === 2)
          ) {
            pointsPlayer1.className = "highlight-points";
            pointsPlayer2.className = "";
          } else {
            pointsPlayer1.className = "";
            pointsPlayer2.className = "highlight-points";
          }
          whosTurn.textContent = myTurn
            ? "Du bist am Zug!"
            : "Dein Gegner ist am Zug!";
        }
      });
    gameLogic.pointsPlayer1.subscribe((x) => {
      pointsPlayer1.textContent = x.toString();
    });
    gameLogic.pointsPlayer2.subscribe((x) => {
      pointsPlayer2.textContent = x.toString();
    });
  } else {
    const sorryText = document.createElement("h1");
    sorryText.textContent =
      "Leider unterstützt dein Browser scheinbar kein WebGL2!";
    gameDiv.appendChild(sorryText);
  }
};

function showCookieBanner(parent: HTMLElement): void {
  if (cookieBannerVisible) {
    return;
  }
  cookieBannerVisible = true;

  const cookieConsentDiv = document.createElement("div");
  cookieConsentDiv.className = "cookie-banner";
  cookieConsentDiv.innerHTML = "<h2>Diese Webseite verwendet Cookies</h2>";
  const cookieConsentText = document.createElement("p");
  cookieConsentText.textContent = `Unsere Partner und wir verwenden Cookies, um dir Werbeanzeigen zu präsentieren.
    Da uns der Datenschutz sehr am Herzen liegt, bitten wir dich hiermit um die Erlaubnis, diese Cookies zu verwenden.
    Du kannst die Einwilligung jederzeit über die Cookie-Einstellungen widerrufen!
    Wenn du ablehnst, werden lediglich technisch notwendige Cookies verwendet.
    Nähere Informationen zu dem Thema findest du in unserer `;
  const legalNoticeLink = document.createElement("a");
  legalNoticeLink.href = "#m134";
  legalNoticeLink.textContent = "Datenschutzerklärung";
  legalNoticeLink.onclick = () => {
    openLegalNotice();
  };
  cookieConsentText.appendChild(legalNoticeLink);
  cookieConsentText.append(".");
  cookieConsentDiv.appendChild(cookieConsentText);

  const buttonsDiv = document.createElement("div");
  buttonsDiv.style.textAlign = "right";
  cookieConsentDiv.appendChild(buttonsDiv);
  const declineButton = document.createElement("a");
  declineButton.href = "#";
  declineButton.textContent = "Ablehnen";
  declineButton.onclick = () => {
    setCookieValue("ALLOW_COOKIES", "ESSENTIAL", 30);
    cookieBannerVisible = false;
    cookieConsentDiv.remove();
    if (provider.knows(Screen)) {
      provider.resolve(Screen).fireResize();
    }
  };
  buttonsDiv.appendChild(declineButton);
  const acceptButton = document.createElement("button");
  acceptButton.className = "btn btn-ok";
  acceptButton.textContent = "Alle Cookies akzeptieren";
  acceptButton.onclick = () => {
    setCookieValue("ALLOW_COOKIES", "ALL", 30);
    activateAdsbygoogle();
    cookieBannerVisible = false;
    cookieConsentDiv.remove();
    if (provider.knows(Screen)) {
      provider.resolve(Screen).fireResize();
    }
  };
  buttonsDiv.appendChild(acceptButton);

  parent.appendChild(cookieConsentDiv);
  if (provider.knows(Screen)) {
    provider.resolve(Screen).fireResize();
  }
}

function createLegalNoticeButton(parent: HTMLElement): void {
  const legalNoticeLink = document.createElement("a");
  legalNoticeLink.href = "#";
  legalNoticeLink.textContent = "Impressum / Datenschutzerklärung";
  legalNoticeLink.onclick = () => {
    openLegalNotice();
  };
  parent.appendChild(legalNoticeLink);
}

function createCookieSettingsButton(): HTMLAnchorElement {
  const cookieSettingsButton = document.createElement("a");
  cookieSettingsButton.href = "#";
  cookieSettingsButton.textContent = "Cookie-Einstellungen";
  return cookieSettingsButton;
}

function openLegalNotice() {
  if (legalNoticeOpen) {
    return;
  }
  legalNoticeOpen = true;

  const windowDiv = document.createElement("div");
  windowDiv.className = "modal-dialog";
  windowDiv.style.bottom = "2em";
  windowDiv.style.zIndex = "3";
  windowDiv.innerHTML = `
  <h1>Impressum / Datenschutzerklärung</h1>
  <div class="content">
    <div class="legal-notice">
      <h4>Angaben gemäß §5 TMG</h4>
      <p>
        Tobias Schmidt<br/>
        Am Langen Reck 33<br/>
        46485 Wesel<br/>
        Germany<br/><br/>
        <b>Kontakt</b><br/>
        Tel.: +49176 95406669<br/>
        E-Mail: info@coding-tobi.io
      </p>
    </div><br/><br/>
    <div style="text-align: left">${IMPRESSUM}</div>
  </div>`;
  document.body.appendChild(windowDiv);

  const footerDiv = document.createElement("div");
  footerDiv.className = "footer";
  windowDiv.appendChild(footerDiv);

  const closeButton = document.createElement("button");
  closeButton.className = "btn btn-ok";
  closeButton.textContent = "Schließen";
  closeButton.onclick = () => {
    windowDiv.remove();
    legalNoticeOpen = false;
  };
  footerDiv.appendChild(closeButton);
}

function getCookieValue(name: string) {
  const value = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return value ? value.pop() : "";
}

function setCookieValue(name: string, value: string, expireDays: number) {
  const expire = new Date(
    new Date().getTime() + 1000 * 60 * 60 * 24 * expireDays
  );
  document.cookie = `${name}=${value};expires=${expire.toUTCString()}`;
}

function activateAdsbygoogle() {
  const script = document.createElement("script");
  script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
  script.setAttribute("data-ad-client", "ca-pub-5642278673633269");
  document.head.appendChild(script);
}

import { fromEvent, Observable, pipe } from "rxjs";
import { filter, map } from "rxjs/operators";

import provider from "../provider";
import Renderer from "../rendering/renderer";
import { vec2 } from "gl-matrix";

export default class Input {
  private readonly _mousePosition: vec2;

  public readonly keyDown$: Observable<IKeyEvent>;
  public readonly keyUp$: Observable<IKeyEvent>;
  public readonly click$: Observable<IMouseEvent>;
  public readonly mouseMove$: Observable<IMouseEvent>;

  constructor() {
    this._mousePosition = vec2.create();
    const renderer = provider.resolve(Renderer);

    const mouseEventPipe = pipe(
      filter<MouseEvent>((x) => x.srcElement === renderer.view),
      map((e) => this.mapMouseEvent(e, renderer))
    );

    this.keyDown$ = fromEvent<KeyboardEvent>(window, "keydown").pipe(
      filter((e) => !e.repeat)
    );
    this.keyUp$ = fromEvent<KeyboardEvent>(window, "keyup");
    this.click$ = fromEvent<MouseEvent>(window, "click").pipe(mouseEventPipe);
    this.mouseMove$ = fromEvent<MouseEvent>(window, "mousemove").pipe(
      mouseEventPipe
    );
  }

  private mapMouseEvent(e: MouseEvent, renderer: Renderer): IMouseEvent {
    vec2.set(
      this._mousePosition,
      e.clientX / renderer.view.width,
      e.clientY / renderer.view.width
    );
    return {
      button: e.button,
      position: this._mousePosition,
      ctrlKey: e.ctrlKey,
      shiftKey: e.shiftKey,
      altKey: e.altKey,
    };
  }
}

export interface IKeyModifier {
  ctrlKey: boolean;
  shiftKey: boolean;
  altKey: boolean;
}

export interface IKeyEvent extends IKeyModifier {
  code: string;
}

export interface IMouseEvent extends IKeyModifier {
  button: number;
  position: vec2;
}

import VertexArrayObject from "./vertex-array-object";

export default class Primitive {
  public readonly drawMode: number;

  public get vertexArrayObject() {
    return this.options.vertexArrayObject;
  }

  public get firstVertex() {
    return this.options.firstVertex ?? 0;
  }

  public get vertexCount() {
    return this.options.vertexCount;
  }

  constructor(
    gl: WebGL2RenderingContext,
    private readonly options: IPrimitiveOptions
  ) {
    this.drawMode = this.getDrawMode(gl, options.primitiveType);
  }

  private getDrawMode(
    gl: WebGL2RenderingContext,
    primitiveType: PrimitiveType
  ): number {
    switch (primitiveType) {
      case PrimitiveType.Points:
        return gl.POINTS;
      case PrimitiveType.Lines:
        return gl.LINES;
      case PrimitiveType.LineStrip:
        return gl.LINE_STRIP;
      case PrimitiveType.LineLoop:
        return gl.LINE_LOOP;
      case PrimitiveType.Triangles:
        return gl.TRIANGLES;
      case PrimitiveType.TriangleStrip:
        return gl.TRIANGLE_STRIP;
      case PrimitiveType.TriangleFan:
        return gl.TRIANGLE_FAN;
    }
  }
}

export enum PrimitiveType {
  Points,
  Lines,
  LineStrip,
  LineLoop,
  Triangles,
  TriangleStrip,
  TriangleFan,
}

export interface IPrimitiveOptions {
  vertexArrayObject: VertexArrayObject;
  primitiveType: PrimitiveType;
  firstVertex?: number;
  vertexCount: number;
}

import { mat3 } from "gl-matrix";
import Component from "./component";
import SmartTransform from "./common/smart-transform";

export default abstract class Node extends Component {
  private readonly _children: Node[];

  private readonly _localTransform: SmartTransform;
  private readonly _worldTransform: mat3;

  public get children() {
    return this._children;
  }

  public get localTransform() {
    return this._localTransform;
  }

  public get worldTransform() {
    return this._worldTransform;
  }

  constructor() {
    super();
    this._children = [];
    this._localTransform = new SmartTransform();
    this._worldTransform = mat3.create();
  }

  /**
   * Adds a child and loads it if this entity is loaded.
   * @param child Entity to add
   */
  public addChild(child: Node) {
    this._children.push(child);
    child.setParent(this);
  }

  /**
   * Removes a child unloads it if this entity is loaded.
   * @param child Entity to remove
   */
  public removeChild(child: Node) {
    const index = this._children.indexOf(child);
    if (0 <= index) {
      child.orphan();
      this._children.splice(index, 1);
    }
  }

  protected computeWorldTransform(parentWorldTransform: mat3) {
    mat3.multiply(
      this._worldTransform,
      parentWorldTransform,
      this._localTransform.value
    );
    this.children.forEach(child =>
      child.computeWorldTransform(this._worldTransform)
    );
  }
}

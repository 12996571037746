import { mat3, vec2 } from "gl-matrix";

export default class SmartTransform {
  private _value: mat3;
  private _dirty: boolean;
  private _position: vec2;
  private _scale: vec2;
  private _angle: number;

  public get value() {
    if (this._dirty) {
      mat3.fromTranslation(this._value, this._position);
      mat3.scale(this._value, this._value, this._scale);
      mat3.rotate(this._value, this._value, this._angle);
      this._dirty = false;
    }
    return this._value;
  }

  public get position() {
    return this._position;
  }

  public get scale() {
    return this._scale;
  }

  public get angle() {
    return this._angle;
  }

  constructor() {
    this._value = mat3.create();
    this._dirty = false;
    this._position = vec2.create();
    this._scale = vec2.fromValues(1, 1);
    this._angle = 0;
  }

  public setPosition(x: number, y: number) {
    vec2.set(this._position, x, y);
    this._dirty = true;
  }

  public setScale(x: number, y?: number) {
    vec2.set(this._scale, x, y ?? x);
    this._dirty = true;
  }

  public setAngle(angle: number) {
    this._angle = angle;
    this._dirty = true;
  }
}

import { mat3, vec2, ReadonlyVec2 } from "gl-matrix";

export function orthogonalProjection(
  left: number,
  top: number,
  right: number,
  bottom: number
): mat3 {
  const scaling = mat3.fromScaling(
    mat3.create(),
    vec2.fromValues(2 / (right - left), 2 / (top - bottom))
  );
  const translation = mat3.fromTranslation(
    mat3.create(),
    vec2.fromValues((-left - right) / 2, (-bottom - top) / 2)
  );

  return mat3.multiply(mat3.create(), scaling, translation);
}

export function normalOrthogonalProjection(width: number, height: number) {
  return orthogonalProjection(0, 0, 1, height / (width ?? 0.0001));
}

export function mixNumber(a: number, b: number, alpha: number): number {
  return a + (b - a) * alpha;
}

export function mixVec2(
  out: vec2,
  a: ReadonlyVec2,
  b: ReadonlyVec2,
  alpha: number
): vec2 {
  vec2.subtract(out, b, a);
  vec2.scale(out, out, alpha);
  return vec2.add(out, out, a);
}

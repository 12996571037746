import { mat3, vec4 } from "gl-matrix";
import Program from "./program";

export abstract class Uniform {
  constructor(public readonly name: string) {}

  public abstract apply(program: Program): void;
}

abstract class AbstractGenericUniform<T> extends Uniform {
  constructor(name: string, public value: T) {
    super(name);
  }

  public apply(program: Program): void {
    this.glApply(program.gl, program.getUniformLocation(this.name));
  }

  protected abstract glApply(
    gl: WebGL2RenderingContext,
    uniformLocation: WebGLUniformLocation
  ): void;
}

export class Uniform4fv extends AbstractGenericUniform<vec4> {
  constructor(name: string, value: vec4) {
    super(name, value);
  }

  protected glApply(
    gl: WebGL2RenderingContext,
    uniformLocation: WebGLUniformLocation
  ): void {
    gl.uniform4fv(uniformLocation, this.value);
  }
}

export class UniformMatrix3fv extends AbstractGenericUniform<mat3> {
  constructor(name: string, value: mat3) {
    super(name, value);
  }

  protected glApply(
    gl: WebGL2RenderingContext,
    uniformLocation: WebGLUniformLocation
  ): void {
    gl.uniformMatrix3fv(uniformLocation, false, this.value);
  }
}

export class Uniform1ui extends AbstractGenericUniform<number> {
  constructor(name: string, value: number) {
    super(name, value);
  }

  protected glApply(
    gl: WebGL2RenderingContext,
    uniformLocation: WebGLUniformLocation
  ): void {
    gl.uniform1ui(uniformLocation, this.value);
  }
}

export default class MultiMap<K, V> {
  private _map: Map<K, V[]>;

  constructor() {
    this._map = new Map<K, V[]>();
  }

  public set(key: K, value: V) {
    const arrayOfKey = this._map.get(key);
    if (arrayOfKey) {
      const indexOfValue = arrayOfKey.indexOf(value);
      if (0 <= indexOfValue) {
        arrayOfKey[indexOfValue] = value;
      } else {
        arrayOfKey.push(value);
      }
    } else {
      this._map.set(key, [value]);
    }
  }

  public get(key: K): V[] {
    return this._map.get(key) ?? [];
  }

  public has(key: K, value?: V): boolean {
    if (value) {
      return !!this._map.get(key)?.includes(value);
    } else {
      return this._map.has(key);
    }
  }

  public delete(key: K, value: V) {
    const arrayOfKey = this._map.get(key);
    if (arrayOfKey) {
      const indexOfValue = arrayOfKey.indexOf(value);
      arrayOfKey.splice(indexOfValue, 1);
      if (!arrayOfKey.length) {
        this._map.delete(key);
      }
    }
  }
}

import constructor from "./common/constructor";
import Node from "./node";
import Component from "./component";
import MultiMap from "./common/multi-map";

export default class Entity extends Node {
  private readonly _components: MultiMap<constructor, Component>;

  constructor() {
    super();
    this._components = new MultiMap<constructor, Component>();
  }

  /**
   * Adds a component and loads it if this entity is loaded.
   * @param component Component to add
   */
  public addComponent<T extends Component>(ctor: constructor<T>, component: T) {
    this._components.set(ctor, component);
    component.setParent(this);
  }

  /**
   * Removes a component and unloads it if this entity is loaded.
   * @param component Component to remove
   */
  public removeComponent<T extends Component>(
    ctor: constructor<T>,
    component: T
  ) {
    if (this._components.has(ctor, component)) {
      component.orphan();
      this._components.delete(ctor, component);
    }
  }

  /**
   * Gets the first Component with the given constructor of this Entity.
   * @param ctor constructor
   */
  public getComponent<T extends Component>(ctor: constructor<T>): T | null {
    return <T | null>(this._components.get(ctor)[0] ?? null);
  }

  /**
   * Get all Components with the given constructor of this Entiy.
   * @param ctor constructor
   */
  public getComponents<T extends Component>(ctor: constructor<T>): T[] {
    return <T[]>this._components.get(ctor);
  }

  /**
   * Recursively finds the first Component with the given constructor.
   * Searching up the tree.
   * @param ctor constructor
   */
  public findComponent<T extends Component>(ctor: constructor<T>): T | null {
    return this.findComponents(ctor).next().value ?? null;
  }

  /**
   * Recursively finds all Components with the given constructor.
   * Searching up the tree.
   * @param ctor constructor
   */
  public *findComponents<T extends Component>(
    ctor: constructor<T>
  ): IterableIterator<T> {
    yield* this.getComponents(ctor);
    for (const child of this.children) {
      if (child instanceof Entity) {
        yield* child.findComponents(ctor);
      }
    }
  }

  /**
   * Resolves the first Component with the given constructor.
   * Searches down the tree.
   * @param ctor constructor
   */
  public resolveComponent<T extends Component>(ctor: constructor<T>): T | null {
    return this.resolveComponents(ctor).next().value ?? null;
  }

  /**
   * Recursively resolves all Components with the given constructor.
   * Searching down the tree.
   * @param ctor constructor
   */
  public *resolveComponents<T extends Component>(
    ctor: constructor<T>
  ): IterableIterator<T> {
    yield* this.getComponents(ctor);
    if (this.parent) {
      yield* this.parent.resolveComponents(ctor);
    }
  }
}

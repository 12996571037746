import { range, animationFrameScheduler, Observable } from "rxjs";
import { share, map, pairwise } from "rxjs/operators";

export const TICKER_SYMBOL = Symbol();

export class Ticker implements ITicker {
  public readonly frameTicker$: Observable<IFrameInfo>;

  constructor() {
    this.frameTicker$ = range(
      0,
      Number.POSITIVE_INFINITY,
      animationFrameScheduler
    ).pipe(
      map((frameNumber) => ({
        frameNumber,
        timeSeconds: new Date().getTime() / 1000.0,
      })),
      pairwise(),
      map(
        ([prev, cur]) =>
          ({
            frameNumber: cur.frameNumber,
            timeSeconds: cur.timeSeconds,
            deltaTimeSeconds: cur.timeSeconds - prev.timeSeconds || 0.0001,
          } as IFrameInfo)
      ),
      share()
    );
  }
}

export interface ITicker {
  frameTicker$: Observable<IFrameInfo>;
}

export interface IFrameInfo {
  frameNumber: number;
  deltaTimeSeconds: number;
  timeSeconds: number;
}

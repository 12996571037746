import { Observable, pipe } from "rxjs";
import { mat3, vec2 } from "gl-matrix";

import provider from "../provider";
import Component from "../component";
import Node from "../node";
import Input, { IMouseEvent } from "../injections/input";
import Shape from "../physics/shape";
import {
  withLatestFrom,
  filter,
  map,
  distinctUntilKeyChanged,
  pairwise,
} from "rxjs/operators";
import Entity from "../entity";

export default class MouseInputComponent extends Component {
  private readonly _inverseWorldTransform: mat3;
  private readonly _modelSpaceMousePosition: vec2;

  public readonly click$: Observable<IMouseInfo>;
  public readonly move$: Observable<IMouseInfo>;
  public readonly in$: Observable<IMouseEvent>;
  public readonly out$: Observable<IMouseEvent>;

  constructor(options: IMouseInputOptions) {
    super();

    const input = provider.resolve(Input);
    this._inverseWorldTransform = mat3.create();
    this._modelSpaceMousePosition = vec2.create();

    const mouseOverPipe = pipe(
      withLatestFrom(this.attachBehaviorSubject$),
      filter<[IMouseEvent, boolean | null]>(([, attached]) => !!attached),
      map(([mouseEvent]) => ({
        ...mouseEvent,
        mouseOver: this.checkMouseOver(mouseEvent, options.shape),
      }))
    );

    const mouseOver$ = input.mouseMove$.pipe(mouseOverPipe);
    const mouseInOut$ = mouseOver$.pipe(
      pairwise(),
      filter(([prev, cur]) => prev.mouseOver !== cur.mouseOver),
      map(([, cur]) => cur)
    );

    this.click$ = input.click$.pipe(
      mouseOverPipe,
      filter((x) => x.mouseOver),
      map(this.mouseEventToInfo)
    );
    this.move$ = input.mouseMove$.pipe(
      mouseOverPipe,
      filter((x) => x.mouseOver),
      map(this.mouseEventToInfo)
    );
    this.in$ = mouseInOut$.pipe(
      filter((x) => x.mouseOver),
      map(this.mouseEventToInfo)
    );
    this.out$ = mouseInOut$.pipe(
      filter((x) => !x.mouseOver),
      map(this.mouseEventToInfo)
    );
  }

  private checkMouseOver(mouseEvent: IMouseEvent, shape: Shape): boolean {
    mat3.invert(
      this._inverseWorldTransform,
      (<Node>this.parent).worldTransform
    );
    vec2.transformMat3(
      this._modelSpaceMousePosition,
      mouseEvent.position,
      this._inverseWorldTransform
    );
    return shape.containsPoint(this._modelSpaceMousePosition);
  }

  private mouseEventToInfo(e: IMouseEvent): IMouseInfo {
    return {
      ...e,
      sender: <Entity>this.parent,
    };
  }
}

export interface IMouseInputOptions {
  shape: Shape;
}

export interface IMouseInfo extends IMouseEvent {
  sender: Entity;
}

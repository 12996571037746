import { vec2, vec4 } from "gl-matrix";
import { filter, delay } from "rxjs/operators";

import GameLogic from "./game-logic";
import Entity from "~/base/entity";
import provider from "~/base/provider";
import Primitive from "~/base/rendering/primitive";
import Material from "~/base/rendering/material";
import ModelComponent from "~/base/components/model-component";
import { Uniform4fv } from "~/base/rendering/uniform";
import { playNumberAnimation } from "~/base/animation/animation";
import {
  easeOutElastic,
  easeInQuad,
  easeInOutQuad,
} from "~/base/animation/easing";
import {
  SQUARE_PRIMITIVE_TOKEN,
  ARROW_MATERIAL_TOKEN,
  CROSS_MATERIAL_TOKEN,
} from "./gl-stuff";
import ColumnEntity from "./column-entity";

export default class ArrowEntity extends Entity {
  private static readonly Y_POS = -0.6;

  constructor(options: IArrowOptions) {
    super();

    this.localTransform.setPosition(0.5, ArrowEntity.Y_POS);
    this.localTransform.setScale(0.4);

    // models
    const squarePrimitive = provider.resolve<Primitive>(SQUARE_PRIMITIVE_TOKEN);
    const arrowMaterial = provider.resolve<Material>(ARROW_MATERIAL_TOKEN);
    const arrowModelComponent = new ModelComponent({
      modelOptions: {
        layer: 1,
        material: arrowMaterial,
        primitive: squarePrimitive,
      },
      anchor: vec2.fromValues(0.5, 0.5),
    });
    const crossMaterial = provider.resolve<Material>(CROSS_MATERIAL_TOKEN);
    const crossModelComponent = new ModelComponent({
      modelOptions: {
        layer: 1,
        material: crossMaterial,
        primitive: squarePrimitive,
      },
      anchor: vec2.fromValues(0.5, 0.5),
    });

    const gameLogic = provider.resolve(GameLogic);
    gameLogic.playerColor$.subscribe((color) => {
      crossModelComponent.model.setUniform(new Uniform4fv("uTint", color));
      arrowModelComponent.model.setUniform(new Uniform4fv("uTint", color));
    });

    // only show up when it is my turn!
    gameLogic.myTurn$
      .pipe(
        filter((myTurn) => myTurn),
        delay(options.column * 50)
      )
      .subscribe(() => {
        if (this.isParentColumnFull()) {
          this.addComponent(ModelComponent, crossModelComponent);
        } else {
          this.addComponent(ModelComponent, arrowModelComponent);
        }
        this.goof();
      });
    gameLogic.myTurn$.pipe(filter((myTurn) => !myTurn)).subscribe(() => {
      this.removeComponent(ModelComponent, crossModelComponent);
      this.removeComponent(ModelComponent, arrowModelComponent);
    });
  }

  public goof() {
    if (this.isParentColumnFull()) {
      playNumberAnimation(
        this.localTransform.position[0],
        [
          {
            toValue: 0.6,
            timeSeconds: 0.2,
            easing: easeOutElastic,
          },
          {
            toValue: 0.4,
            timeSeconds: 0.2,
            easing: easeOutElastic,
          },
          {
            toValue: 0.5,
            timeSeconds: 0.15,
            easing: easeInOutQuad,
          },
        ],
        this.frameTick$
      ).subscribe((x) => this.localTransform.setPosition(x, ArrowEntity.Y_POS));
    } else {
      playNumberAnimation(
        this.localTransform.position[1],
        [
          {
            toValue: ArrowEntity.Y_POS - 0.25,
            timeSeconds: 0.2,
            easing: easeInQuad,
          },
          {
            toValue: ArrowEntity.Y_POS,
            timeSeconds: 0.35,
            easing: easeOutElastic,
          },
        ],
        this.frameTick$
      ).subscribe((x) => this.localTransform.setPosition(0.5, x));
    }
  }

  private isParentColumnFull(): boolean {
    return (<ColumnEntity | null>this.parent)?.isFull ?? false;
  }
}

export interface IArrowOptions {
  column: number;
}

import constructor from "./common/constructor";

export type Token<T = any> = constructor<T> | string | symbol;
export type Factory<T = any> = () => T;
export type Registration<T = any> = {
  factory?: Factory<T>;
  instance?: T;
};

export class Provider {
  private _registry: Map<Token, Registration>;

  constructor() {
    this._registry = new Map<Token, Registration>();
  }

  public registerInstance<T extends Object>(token: Token<T>, instance: T) {
    this._registry.set(token, { instance });
  }

  public registerSingleton<T extends Object>(
    token: Token<T>,
    factory: Factory<T>
  ) {
    this._registry.set(token, { factory });
  }

  public resolve<T extends Object>(token: Token<T>): T {
    console.assert(this._registry.has(token), "Token is not registered!");
    const registration = <Registration<T>>this._registry.get(token);
    if (registration.instance) {
      return registration.instance;
    } else {
      console.assert(
        registration.factory,
        "Neither an instance nor a factory is registered!"
      );
      registration.instance = (<Factory<T>>registration.factory)();
      return registration.instance;
    }
  }

  public knows<T extends Object>(token: Token<T>): boolean {
    return this._registry.has(token);
  }
}

const provider = new Provider();
export default provider;

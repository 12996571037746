export default class VertexAttribute {
  public get name() {
    return this.options.name;
  }

  constructor(private readonly options: IVertexAttributeOptions) {}

  public enable(gl: WebGL2RenderingContext, attributeLocation: number) {
    gl.enableVertexAttribArray(attributeLocation);
    gl.vertexAttribPointer(
      attributeLocation,
      this.options.size,
      gl.FLOAT,
      false,
      this.options.stride,
      this.options.offset
    );
  }
}

export interface IVertexAttributeOptions {
  name: string;
  size: number;
  stride: number;
  offset: number;
}

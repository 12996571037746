export function range(size: number, start: number = 0): number[] {
  return [...Array(size).keys()].map((i) => i + start);
}

export function cross<T, U>(first: T[], second: U[]): Array<[T, U]>;
export function cross<T, U, V>(first: T[], second: U[], third: V[]): Array<[T, U, V]>;
export function cross<T, U, V, W>(first: T[], second: U[], third: V[], fourth: W[]): Array<[T, U, V, W]>;
export function cross(...arrays: any[][]): any[][] {
  return recursiveCross(...arrays);
}
function recursiveCross(...arrays: any[][]): any[][] {
  const [head, ...rest] = arrays;
  if (rest.length) {
    return head.reduce(
      (acc, cur) =>
        acc.concat(
          recursiveCross(...rest).map((x) =>
            Array.isArray(x) ? [cur, ...x] : [cur, x]
          )
        ),
      []
    );
  } else {
    return head;
  }
}

export function allEqual<T>(a: T[]): boolean {
  if (!a.length) {
    return false;
  }
  const first = a[0];
  return a.every((x) => x === first);
}

import Shader from "./shader";
import { mat3 } from "gl-matrix";

export default class Program {
  private _glProgram: WebGLProgram | null;
  private _attributeLocations: IAttributeLocations;
  private _uniformLocations: IUniformLocations;

  public get glProgram() {
    if (!this._glProgram) {
      this._glProgram = this.link();
    }
    return this._glProgram;
  }

  public get modelViewMatrixUniformName() {
    return this.options.modelViewMatrixUniformName;
  }

  constructor(
    public readonly gl: WebGL2RenderingContext,
    private readonly options: IProgramOptions
  ) {
    this._glProgram = null;
    this._attributeLocations = {};
    this._uniformLocations = {};
  }

  public use() {
    this.gl.useProgram(this.glProgram);
  }

  public setProjectionMatrix(projectionMatrix: mat3) {
    if (this.options.projectionMatrixUniformName) {
      this.gl.uniformMatrix3fv(
        this.getUniformLocation(this.options.projectionMatrixUniformName),
        false,
        projectionMatrix
      );
    }
  }

  public getAttributeLocation(attributeName: string): number {
    if (!this._attributeLocations[attributeName]) {
      this._attributeLocations[attributeName] = this.gl.getAttribLocation(
        this.glProgram,
        attributeName
      );
      console.assert(
        0 <= this._attributeLocations[attributeName],
        `Attribute [${attributeName}] not found!`
      );
    }
    return this._attributeLocations[attributeName];
  }

  public getUniformLocation(uniformName: string): WebGLUniformLocation {
    if (!this._uniformLocations[uniformName]) {
      this._uniformLocations[uniformName] = <WebGLUniformLocation>(
        this.gl.getUniformLocation(this.glProgram, uniformName)
      );
      console.assert(
        this._uniformLocations[uniformName],
        `Uniform location [${uniformName}] not found!`
      );
    }

    return this._uniformLocations[uniformName];
  }

  private link(): WebGLProgram {
    const program = <WebGLProgram>this.gl.createProgram();
    console.assert(program, "Creating the program failed!");

    this.gl.attachShader(program, this.options.vertexShader.glShader);
    this.gl.attachShader(program, this.options.fragmentShader.glShader);
    this.gl.linkProgram(program);
    console.assert(
      this.gl.getProgramParameter(program, this.gl.LINK_STATUS),
      "Linking the program failed! " + this.gl.getProgramInfoLog(program)
    );

    return program;
  }
}

export interface IAttributeLocations {
  [name: string]: number;
}

export interface IUniformLocations {
  [name: string]: WebGLUniformLocation;
}

export interface IProgramOptions {
  vertexShader: Shader;
  fragmentShader: Shader;
  modelViewMatrixUniformName?: string;
  projectionMatrixUniformName?: string;
}

import { mat3 } from "gl-matrix";
import Node from "./node";

export default class Scene extends Node {
  private readonly _rootTransform: mat3;

  constructor() {
    super();
    this._rootTransform = mat3.create();
  }

  public attach() {
    this.attachBehaviorSubject$.next(true);
  }

  public detach() {
    this.attachBehaviorSubject$.next(false);
  }

  public computeChildrenWorldTransforms() {
    this.computeWorldTransform(this._rootTransform);
  }
}
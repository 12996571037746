import { vec2, vec4 } from "gl-matrix";

import Entity from "~/base/entity";
import provider from "~/base/provider";
import Primitive from "~/base/rendering/primitive";
import Material from "~/base/rendering/material";
import ModelComponent from "~/base/components/model-component";
import { easeOutBounce } from "~/base/animation/easing";
import { animateVec2 } from "~/base/animation/animation";

import { SQUARE_PRIMITIVE_TOKEN, COIN_MATRIAL_TOKEN } from "./gl-stuff";
import { Uniform4fv } from "~/base/rendering/uniform";
import GameLogic from "./game-logic";

export default class CoinEntity extends Entity {
  public static readonly SIZE = 0.96;

  constructor(private readonly options: ICoinOptions) {
    super();

    this.localTransform.setPosition(0.5, -1.5);
    this.localTransform.setScale(CoinEntity.SIZE);

    // model
    const squarePrimitive = provider.resolve<Primitive>(SQUARE_PRIMITIVE_TOKEN);
    const tileMaterial = provider.resolve<Material>(COIN_MATRIAL_TOKEN);
    const tileModelComponent = new ModelComponent({
      modelOptions: {
        layer: 0,
        material: tileMaterial,
        primitive: squarePrimitive,
      },
      anchor: vec2.fromValues(0.5, 0.5),
    });
    tileModelComponent.model.setUniform(
      new Uniform4fv(
        "uTint",
        GameLogic.getPlayerColor(options.playerNumber)
      )
    );
    this.addComponent(ModelComponent, tileModelComponent);
  }

  public drop() {
    animateVec2(
      vec2.fromValues(0.5, -1.5),
      vec2.fromValues(0.5, this.options.row + 0.5),
      0.75,
      easeOutBounce,
      this.frameTick$
    ).subscribe((x) => this.localTransform.setPosition(x[0], x[1]));
  }
}

export interface ICoinOptions {
  column: number;
  row: number;
  playerNumber: number;
}

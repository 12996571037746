import { Uniform4fv, Uniform1ui } from "~/base/rendering/uniform";
import Entity from "~/base/entity";
import provider from "~/base/provider";
import Primitive from "~/base/rendering/primitive";
import ModelComponent from "~/base/components/model-component";
import Material from "~/base/rendering/material";
import GameLogic from "./game-logic";

import { SQUARE_PRIMITIVE_TOKEN, TILE_MATERIAL_TOKEN } from "./gl-stuff";

export default class TileEntity extends Entity {
  constructor() {
    super();

    // model
    const squarePrimitive = provider.resolve<Primitive>(SQUARE_PRIMITIVE_TOKEN);
    const tileMaterial = provider.resolve<Material>(TILE_MATERIAL_TOKEN);
    const tileModelComponent = new ModelComponent({
      modelOptions: {
        layer: 1,
        material: tileMaterial,
        primitive: squarePrimitive,
      },
    });
    tileModelComponent.model.setUniform(
      new Uniform4fv("uTint", GameLogic.BOARD_COLOR)
    );
    tileModelComponent.model.setUniform(new Uniform1ui("uTile", 1));
    tileModelComponent.model.setUniform(new Uniform1ui("uTiling", 1));
    this.addComponent(ModelComponent, tileModelComponent);
  }
}
